import { differenceInBusinessDays, max, min } from 'date-fns';
import _ from 'lodash';
import { Department } from 'shared/types/Department';
import { PhaseKind, PhaseKinds } from 'shared/types/PhaseKind';
import { Hour } from 'src/models/Hour';
import { Project } from 'src/models/Project';

export interface Phase {
  id: string;
  project: Project;
  kind: PhaseKind;
  initialAmount: number;
  additionalAmount: number;
  consumedAmount?: number;
  progressRate?: number;
  startDate?: Date;
  endDate?: Date;
  missionDuration: number;
  validationDuration: number;
  thermalRate?: number;
  electricityRate?: number;
  structuralRate?: number;
  economyRate?: number;
  thermalUsers?: string[];
  electricityUsers?: string[];
  structuralUsers?: string[];
  economyUsers?: string[];
}

export const PhaseSort = (a: Phase, b: Phase) =>
  a.project.reference.localeCompare(b.project.reference) === 0
    ? PhaseKinds.indexOf(a.kind) - PhaseKinds.indexOf(b.kind)
    : a.project.reference.localeCompare(b.project.reference);

export const getDepartmentRate = (phase: Phase, department: Department) => {
  if (department === 'ECONOMY') {
    return phase.economyRate;
  } else if (department === 'THERMAL') {
    return phase.thermalRate;
  } else if (department === 'ELECTRICITY') {
    return phase.electricityRate;
  } else if (department === 'STRUCTURAL') {
    return phase.structuralRate;
  }
};
export const DepartmentLabels = {
  ECONOMY: 'Economie',
  THERMAL: 'Thermique',
  ELECTRICITY: 'Electricité',
  STRUCTURAL: 'Structure',
};

export type PhaseWithRange = Phase & {
  range: { startDate: Date; endDate: Date };
};

export const getRangeFee = (phase: PhaseWithRange, department?: Department) => {
  if (phase.endDate && phase.startDate) {
    return (
      ((phase.initialAmount + phase.additionalAmount) *
        (department ? getDepartmentRate(phase, department) ?? 0 : 100) *
        (phase.progressRate ?? 0) *
        getDayRate(
          phase.range.startDate,
          phase.range.endDate,
          phase.startDate,
          phase.endDate
        )) /
      (100 * 100)
    );
  } else {
    return 0;
  }
};

const getDayRate = (
  startDate: Date,
  endDate: Date,
  phaseStartDate: Date,
  phaseEndDate: Date
) => {
  if (differenceInBusinessDays(phaseEndDate, phaseStartDate) === 0) {
    return 1;
  } else {
    return (
      differenceInBusinessDays(
        min([endDate, phaseEndDate]),
        max([startDate, phaseStartDate])
      ) / differenceInBusinessDays(phaseEndDate, phaseStartDate)
    );
  }
};

export const getRemainedFee = (
  phase: Phase,
  hours: Hour[],
  department?: Department
) => {
  return (
    ((phase.initialAmount + phase.additionalAmount) *
      (department ? getDepartmentRate(phase, department) ?? 0 : 100)) /
      100 -
    _.sumBy(
      hours.filter((hour) => hour.department === department),
      'cost'
    )
  );
};
